@use "../common/breakpoints" as media;
@use "../common/mixin" as *;
@use "../common/color" as *;

.Instruction {
  padding-bottom: 50px;

  @media #{media.$extra-small-device} {
    margin-bottom: 30px;
  }

  &.wrapper {
    padding-top: 0;
  }
  .morphisam {
    @include glassmorphism-effect;
    padding: 30px 15px;

    &::before {
      position: absolute;
      left: -120px;
      content: "";
      top: -150px;
      width: 300px;
      height: 300px;
      background-color: $white;
      opacity: 0.2;
      border-color: $white;
      border-radius: 50%;
    }

    p {
      color: $white;
    }
  }
}
